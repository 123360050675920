<!--
* @description:
* @fileName shopManage.vue
* @author hvv
* @date 2022/01/13 15:21:21
!-->
<template>
  <div>
    <vab-query-form style="padding-top: 20px">
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="事件名称">
            <el-input
              placeholder="请输入主办名称"
              v-model="queryForm.name"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="事件名称" prop="name" show-overflow-tooltip />
      <el-table-column
        label="事件描述"
        prop="description"
        show-overflow-tooltip
      />
      <el-table-column
        label="事件简称"
        prop="shortName"
        show-overflow-tooltip
      />
      <el-table-column label="事件地址" prop="address" show-overflow-tooltip />
      <el-table-column label="操作" show-overflow-tooltip width="300">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            维护通道
          </el-button>
          <!-- <el-button type="info" @click="handleData(row)">
            <vab-icon icon="dashboard-line" />
            数据通览
          </el-button> -->

          <!-- <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button> -->
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNumber"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
    onActivated,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import { getEventList } from '@/api/channel'
  export default defineComponent({
    name: 'channelManagar',
    components: {},
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryForm: {
          name: '',
          pageNum: 1,
          pageSize: 20,
          appKey: 'WX_MINI_MEHZ',
        },
        total: 0,
        shopList: [],
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
        typeOptions: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '申请会议',
            value: 'HOLD_MEETING',
          },
          {
            label: '申请展览',
            value: 'EXHIBITION',
          },
        ],
      })

      const handleEdit = (row) => {
        if (row.eventId) {
          proxy.$router.push({
            path: '/exhibitionManage/channelDefend',
            query: { eventId: row.eventId },
          })
        }
      }

      const handleData = (row) => {
        if (row.eventId) {
          proxy.$router.push({
            path: '/channel/channelData',
            query: { eventId: row.eventId },
          })
        }
      }

      const handleDelete = (row) => {
        if (row.id) {
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await delCoupon(row.id)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      const queryData = () => {
        fetchData()
      }

      const fetchData = async () => {
        state.listLoading = true
        const { data } = await getEventList(state.queryForm)
        console.log(data, '1123123')
        state.list = data.data
        state.total = data.total
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        queryData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNum = val
        queryData()
      }

      onMounted(() => {
        queryData()
      })
      return {
        ...toRefs(state),
        handleEdit,
        handleData,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        Delete,
        Search,
        Plus,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
